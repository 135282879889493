import { defineStore } from 'pinia'
import type { Form, Microdata } from "../models"
import { forEach } from "lodash-es"
import { formatOrganizationForms } from "@/helpers"
import { useNuxtApp } from '#imports'
import type { AxiosInstance } from 'axios'

interface FormsState {
  forms: Form[]
  microdata
}

export const useFormsStore = defineStore('forms', {
  state: (): FormsState => ({
    forms: [],
    microdata: []
  }),
  actions: {
    setForms (forms) {
      this.forms = forms

      this.forms.sort(
        (a, b) =>
          (new Date(b.meta.updatedAt) as any) -
          (new Date(a.meta.updatedAt) as any)
      )
    },
    fetchMicrodata (newData) {
      let microdata = JSON.parse(JSON.stringify(this.microdata)) // deep copy in case of array in array
      let existingEventIds: any[] = []

      forEach(microdata, (eventId: Record<string, { id: string }>) => {
        const id = eventId["@graph"].id
        existingEventIds = [...existingEventIds, id]
      })

      forEach(newData, (eventId: Record<string, { id: string }>) => {
        const id = eventId["@graph"].id
        const toAdd = !existingEventIds.includes(id)

        if (toAdd) {
          microdata = [...microdata, eventId]
        }
      })

      this.microdata = microdata.flat()
    },
    async fetchForms (organizationSlug: string) {
      const { $httpApi } = useNuxtApp()

      return await ($httpApi as AxiosInstance)
        .get(`/organizations/${organizationSlug}/directory-forms?pageSize=100`)
        .then(({ data }) => {
          // remove widgets urls so they're not indexed
          data.data = formatOrganizationForms(data.data)
          this.setForms(data.data)
          return data
        })
    },
    async postReport ({ reason, userEmail, userMessage, url }) {
      const abuseData = {
        reason,
        userEmail,
        userMessage,
        url,
      }

      const { $httpApi } = useNuxtApp()

      return await ($httpApi as AxiosInstance).post("/abuses", abuseData)
    }
  }
})
