import { defineStore } from 'pinia'
import type { Organization, Profile } from "@/models"
import { getJwtInfo, ensureResponse } from "@/services/auth.service"
import { useFormsStore } from './forms.store'
import { useNuxtApp } from '#imports'
import type { AxiosInstance } from 'axios'

interface UserState {
  profile: Profile
  organizations: Organization[]
  selectedOrg: Organization
  isLoading: boolean
}

export const useUserStore = defineStore('user', {
  state: (): UserState => ({
    profile: {},
    organizations: [],
    selectedOrg: {},
    isLoading: false
  }),
  getters: {
    isAdmin: (state) => (organization: any) => {
      return !(
        state.organizations?.find(
          ({ organizationSlug }) => organizationSlug === organization
        ) == null
      )
    }
  },
  actions: {
    setUser (user) {
      this.profile = user
    },
    selectUserOrganization (organization = {}) {
      this.selectedOrg = organization
    },
    revokeUser () {
      this.profile = {}
      this.selectedOrg = {}
      this.organizations = []
    },
    setUserOrganizations (organizations) {
      this.organizations = organizations
    },
    async fetchUser () {
      const { $httpApi } = useNuxtApp()
      const { data } = await ($httpApi as AxiosInstance).get("/agg/user")
      const { user, organizations } = data
      this.setUser(user)

      // default select the first org in the list if the user did not have any orgs stored locally
      const hasOrgs = this.organizations.length > 0
      if (organizations?.length > 0 && !hasOrgs) {
        this.selectUserOrganization(organizations[0])
      }

      this.setUserOrganizations(organizations)
      return { user, organizations }
    },
    async disconnect () {
      const { $httpAuth } = useNuxtApp()
      await ($httpAuth as AxiosInstance)
        .get("/disconnect")
        .catch(console.error)
        .finally(() => {
          this.revokeUser()
        })
    },
    async connect ([username, password, organizationSlug, accessToken]: [
      string,
      string,
      string,
      string
    ]) {
      try {
        const { $httpAuth } = useNuxtApp()
        const connectResponse = await ($httpAuth as AxiosInstance).post(
          "/token",
          { username, password },
          {
            // add token header to 'upgrade' the token and preserve user session
            headers: { Authorization: `Bearer ${accessToken}` },
          }
        )

        ensureResponse(connectResponse)

        const jwtInfo = getJwtInfo(connectResponse?.data?.access_token)

        // if user has FormAdmin rights, it doesn't mean he's admin of the current form
        // reload the form with the new access token to get this information (hasAdminRights)
        const promises = [this.fetchUser()]

        if (jwtInfo?.isAdmin && organizationSlug) {
          const formsStore = useFormsStore()

          promises.push(formsStore.fetchForms(organizationSlug))
        }

        return await Promise.all(promises)
      } catch (error) {
        console.error(error)
        return error
      }
    }
  }
})
